import React, { ReactNode, Suspense, useEffect, useState } from "react";

/**
 * This component allows lazy loading a component.\
 * This was needed as we needed to preserve animations in modals that are triggered on un mount while lazy loading the component itself.\
 *
 * You must pass the dynamically imported component as children to this component.\
 * Dynamic imports can be done in this way:
 * - ReactJs - `React.lazy(() => import("./Relative/Path/To/Modal"));`
 * - NextJs - `dynamic(() => import("./Relative/Path/To/Modal"));`
 */
const LazyLoadWrapper = ({
  children,
  loadingCondition,
}: {
  children: ReactNode;
  loadingCondition?: any;
}) => {
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(loadingCondition);
  useEffect(() => {
    if (loadingCondition && !isFirstLoadDone) setIsFirstLoadDone(true);
  }, [loadingCondition]);

  if (!isFirstLoadDone) return null;
  return <Suspense fallback={null}>{children}</Suspense>;
};

export default LazyLoadWrapper;
